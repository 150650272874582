import React from 'react'
import "./PrivacyPolicy.css"

const PrivacyPolicy = () => {
  return (
    <div class="privacy-policy">
    <h1>Privacy Policy</h1>
    <p>
      Your privacy is important to us. This Privacy Policy explains how we collect, use, disclose, and safeguard your personal information. By accessing or using our services, you consent to the practices described in this Privacy Policy.
    </p>
  
    <h2>Information We Collect</h2>
    <p>
      We may collect information that you voluntarily provide to us, such as your name, email address, and any other information you provide when using our services.
    </p>
  
    <h2>How We Use Your Information</h2>
    <p>
      We may use your information to:
    </p>
    <ul>
      <li>Provide, maintain, and improve our services.</li>
      <li>Respond to your requests, comments, or questions.</li>
      <li>Send you updates, newsletters, or promotional materials.</li>
      <li>Monitor and analyze usage patterns on our website or apps.</li>
    </ul>
  
    <h2>Disclosure of Your Information</h2>
    <p>
      We will not share your personal information with third parties without your consent, except in the following circumstances:
    </p>
    <ul>
      <li>If required by law or legal process.</li>
      <li>To protect our rights, privacy, safety, or property, or the public.</li>
    </ul>
  
    <h2>Security</h2>
    <p>
      We take reasonable measures to protect your personal information from unauthorized access, disclosure, alteration, or destruction. However, no method of online transmission or electronic storage is completely secure, so we cannot guarantee absolute security.
    </p>
  
    <h2>Contact Us</h2>
    <p>
      If you have any questions about this Privacy Policy or our practices, please contact us at: createdigi@hotmail.com
    </p>
  

  </div>
  
  )
}

export default PrivacyPolicy
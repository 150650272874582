import React from 'react'
import "./Home.css";

const Home = () => {
  return (
    <div className="header-wraper">
      <h1>Welcome to My Personal Website</h1>
      <p>
        I'm Bo Shayea, a web developer and app creator. Explore my work, social media, YouTube channel, and more.
      </p>
    </div>
  )
}

export default Home
import React from 'react';
import './App.css'; // You can import your CSS for global styling
import {BrowserRouter, Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import ContactMe from "./pages/ContactMe";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "./components/Navbar";


function App() {
  return (
    <div className="App">
      <header>
        <Navbar />
      </header>
      <main>
        <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/contact-me" element={<ContactMe />} />
      </Routes>
        </BrowserRouter>
      </main>
      <footer className="footer">
        <p>Copyright &copy; 2023 BoShayea</p>
      </footer>
    </div>
  );
}

export default App;

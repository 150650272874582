import React from 'react'
import logo from "../logo.png";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary">
        {/* <div className="container">
            </div> */}
    <div className="container-fluid">
      <a className="navbar-brand" href="/">
        <img className="logo" src={logo} alt="Bo Shayea" />
      </a>
      <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span className="navbar-toggler-icon"></span>
      </button>
      <div className="collapse navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
          <li className="nav-item">
            <a className="nav-link active" aria-current="page" href="/">Home</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/privacy-policy">Privacy Policy</a>
          </li>
          <li className="nav-item">
            <a className="nav-link" href="/contact-me">Contact Me</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
  )
}

export default Navbar
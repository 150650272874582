import React from 'react'

const ContactMe = () => {
  return (
    <div>
      <h1>Contact Me</h1>
      <p>Feel free to get in touch with me using the methods below:</p>
      
      <div className="contact-form">
        {/* Your contact form component */}
      </div>
      
      <div className="social-media-links">
        {/* Social media icons or links */}
      </div>

      <p>Email: createdigi@outlook.com</p>

      {/* Optionally, include a map, address, and business hours */}
    </div>
  )
}

export default ContactMe